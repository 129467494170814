import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import { DataTable, Dialog, Radio, Textarea, notification } from 'tyb'
import moment from 'moment'
import { Confirm, Info } from 'ucode-components'
import $http from '@/utils/http'
import { connect } from 'react-redux';

const StatusMap = {
  APPROVING: '待审核',
  APPROVED: '已审核',
  REJECTED: '审核不通过',
  UNBOUND: '被驳回'
}

const RoleMap = {
  SHOP_OWNER: '店长',
  SHOP_CLERK: '店员'
}

function DealersInfo(props) {
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [confirming, setConfirming] = useState(false)

  const [type, setType] = useState('pass') // pass | reject
  const [remark, setRemark] = useState('')
  const currentAccountId = useRef()

  const getList = useCallback(() => {
    if (!props.code) return
    setLoading(true)
    const dealerCode = props.code;
    $http({
      url: `/channel-openapi/portal/channel/account/dealer/${dealerCode}/v1`,
    })
      .then(res => {
        setLoading(false)
        setList(res || [])
      })
      .catch(() => {
        setLoading(false)
      })
  }, [props.code])
  useEffect(getList, [getList])

  useEffect(() => {
    if (!visible) {
      setType('pass')
      setRemark('')
    }
  }, [visible])

  const onOk = useCallback(() => {
    if (remark.length > 500) {
      notification.error('长度不能超过500')
      return
    }

    setConfirming(true)
    $http({
      url: '/channel-openapi/portal/shops/account/v1/approve',
      method: 'post',
      data: {
        accountId: currentAccountId.current,
        shopCode: props.code,
        approve: type === 'pass',
        rejectReason: remark
      }
    })
      .then(res => {
        setConfirming(false)
        setVisible(false)
        getList()
      })
      .catch(() => {
        setConfirming(false)
      })
  }, [remark, props.code, type, getList])

  const columns = useMemo(
    () => [
      {
        key: 'name',
        dataIndex: 'name',
        title: '店员姓名',
        render: (value, record) => (
          <div>
            <span className="mr-10">{value}</span>
            <span>{record.phone}</span>
          </div>
        )
      },
      {
        key: 'role',
        dataIndex: 'role',
        title: '门店职级',
        render: value => <div>{RoleMap[value]}</div>
      },
      {
        key: 'applyTime',
        dataIndex: 'applyTime',
        title: '申请时间',
        render: value => <div>{value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : ''}</div>
      },
      {
        key: 'status',
        dataIndex: 'status',
        title: '状态',
        render: value => <div>{StatusMap[value]}</div>
      },
      {
        key: '操作',
        dataIndex: '操作',
        title: '操作',
        render: (_, record) => (
          <div>
            {record.status === 'APPROVING' && (
              <button
                className="mr-10 link-button"
                onClick={() => {
                  currentAccountId.current = record.accountId
                  setVisible(true)
                }}>
                审核
              </button>
            )}
            {
              props.permissionIds.includes('data.basicData.dealer.account.approve') &&
              record.status === 'APPROVED' && (
                <button
                  className="mr-10 link-button"
                  onClick={() => {
                    Confirm({
                      type: 'warning',
                      title: '解绑',
                      content: `确认解绑 ${record.name} 店员？`,
                      async onOk() {
                        await $http({
                          url: '/channel-openapi/portal/channel/account/dealer/unbind/v1',
                          method: 'post',
                          data: {
                            accountId: record.accountId,
                            channelCode: props.code,
                            channelType: 'DEALER'
                          }
                        })
                        getList()
                      }
                    })
                  }}>
                  解绑
                </button>
              )
            }
            {record.status === 'REJECTED' && (
              <>
                <button
                  className="mr-10 link-button"
                  onClick={() => {
                    Info({
                      title: '查看',
                      content: (
                        <>
                          <div className="mb-10">审核不通过</div>
                          <div style={{ fontSize: '12px' }}>{record.rejectReason}</div>
                        </>
                      )
                    })
                  }}>
                  查看
                </button>
                <button
                  className="link-button"
                  onClick={() => {
                    Confirm({
                      title: '删除',
                      content: '确认删除该信息？',
                      async onOk() {
                        await $http({
                          url: '/channel-openapi/portal/shops/account/v1/delete',
                          method: 'post',
                          data: {
                            accountId: record.accountId,
                            shopCode: props.code
                          }
                        })
                        getList()
                      }
                    })
                  }}>
                  删除
                </button>
              </>
            )}
          </div>
        )
      }
    ],
    [getList]
  )

  return (
    <div>
      <DataTable columns={columns} data={list} loading={loading} rowKey="id" />

      {visible && (
        <Dialog
          style={{ width: '320px', minWidth: '320px' }}
          title="审核"
          visible={visible}
          confirming={confirming}
          onOk={onOk}
          onCancel={() => {
            setVisible(false)
          }}>
          <div>
            <div className="mb-10">
              <Radio
                checked={type === 'pass'}
                onChange={() => {
                  setType('pass')
                }}>
                审核通过
              </Radio>
            </div>
            <div className="mb-10">
              <Radio
                checked={type === 'reject'}
                onChange={() => {
                  setType('reject')
                }}>
                审核不通过
              </Radio>
            </div>
            {type === 'reject' && (
              <div>
                <Textarea
                  style={{ width: '250px' }}
                  placeholder="请输入"
                  value={remark}
                  onChange={e => {
                    const value = e.target.value
                    if (value.length <= 500) {
                      setRemark(value)
                    }
                  }}
                />
              </div>
            )}
          </div>
        </Dialog>
      )}
    </div>
  )
}

export default connect(
  state => ({
    permissionIds: state.users.permissionIds || []
  }),
  {}
)(DealersInfo)
