// 经销商
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { FieldArray, reduxForm } from "redux-form";
import { StaticFormGroup, FormGroupList, Select, Input, Textarea, Button, ListSelector, Dialog, notification, SearchSelect, PageLoading, TabList } from 'tyb';
import { NewSelect } from 'ucode-components';
import { FormGroupField } from 'tyb';
import { MultipleSelect } from 'ucode-components';
import { TabModified } from 'ucode-components';

import queryString from "query-string";
import moment from "moment";
import './index.scss';
import { Cascader } from 'antd';

const { postDealer, putDealer, loadDealerSelection, dealerById, LoadServiceStaffIds } = iceStarkData.store.get('commonAction')?.datadealers;
const { loadList: loadProductSelect } = iceStarkData.store.get('commonAction')?.products;
const { channelSelection: loadChannelSelection } = iceStarkData.store.get('commonAction')?.channel;


const { getZone } = iceStarkData.store.get('commonAction')?.zone;
const { departmentSelections, departmentsTree } = iceStarkData.store.get('commonAction')?.departments;
const { precinctSelection } = iceStarkData.store.get('commonAction')?.precinct;
const { fieldAssignSelection } = iceStarkData.store.get('commonAction')?.fieldAssign;

import CustomFields from '../components/customFields/customFields';
//图标
import AddIcon from '@/icons/AddIcon';
import SalesRelations from "./components/SalesRelations";
const { loadBrandSelections } = iceStarkData.store.get('commonAction')?.brand
import ShopCredit from '@/pages/BaseManagement/BasicInformationManagement/TerminalManagement/components/ShopCredit.js'
import DealersInfo from './components/DealersInfo';
const { setNavBar } = iceStarkData.store.get('commonAction')?.ui
import DynamicDataSelect from "@/components/DynamicDataSelect";

/**
 * @param {Record<string, any>[]} arr
 */
function disabledAreas(arr) {
  if (arr && arr.length) {
    return arr.map(it => ({
      ...it,
      disabled: true,
      children: disabledAreas(it.children)
    }))
  }
  return []
}

const required = (message) => (value) => {
  return (!value || (value + '').trim() === '' ? `请输入${message}` : undefined);
}

const selected = (message) => (value) => {
  return value && value !== '' ? undefined : `请选择${message}`;
}

const nameRequire = required('经销商名称');

const codeRequire = required('经销商编码');


const regMobile = /^[1][2,3,4,5,6,7,8,9][0-9]{9}$/;
const validateMobile = (val) => {
  // if (!val || val && val.trim().length == 0) {
  //   return "请输入手机号";
  // }
  let value = val && val.trim().length > 0 ? val.trim() : '';
  if (value && !regMobile.test(value)) {
    return "请输入正确的手机号";
  }
}
const regEmail = /^[a-zA-Z0-9\._-]+\@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;//请输入正确的邮箱格式
const validateEmail = (val) => {
  let value = val && val.trim().length > 0 ? val.trim() : '';
  if (value && !regEmail.test(value)) {
    return "请输入正确的邮箱格式";
  }
}
const length = (max) => (value) => {
  return value && value.length > max ? `至多${max}字符` : undefined
}

const len10 = length(10);
const len20 = length(20);
const len50 = length(50);
const len100 = length(100);


const validate1 = (value, values, props, name) => {
  let list = values[name.split('.')[0].split('[')[0]][name.split('.')[0].split('[')[1].split(']')[0]] ? values[name.split('.')[0].split('[')[0]][name.split('.')[0].split('[')[1].split(']')[0]] : [];
  let a = 0;
  if (list.regionId) {
    a++
  }
  if (list.productId) {
    a++
  }
  if (list.channelId) {
    a++
  }
  if (a >= 2) { }
  else {
    return '至少选择两个'
  }


}
const validate2 = (value, values, props, name) => {
  let list = values[name.split('.')[0].split('[')[0]][name.split('.')[0].split('[')[1].split(']')[0]] ? values[name.split('.')[0].split('[')[0]][name.split('.')[0].split('[')[1].split(']')[0]] : [];
  let a = 0;
  if (list.regionId) {
    a++
  }
  if (list.productId) {
    a++
  }
  if (list.channelId) {
    a++
  }
  if (a >= 2) { }
  else {
    return '至少选择两个'
  }

}
const validate3 = (value, values, props, name) => {
  let list = values[name.split('.')[0].split('[')[0]][name.split('.')[0].split('[')[1].split(']')[0]] ? values[name.split('.')[0].split('[')[0]][name.split('.')[0].split('[')[1].split(']')[0]] : [];
  let a = 0;
  if (list.regionId) {
    a++
  }
  if (list.productId) {
    a++
  }
  if (list.channelId) {
    a++
  }
  if (a >= 2) { }
  else {
    return '至少选择两个'
  }

}


const table = ({ fields, channelSelection, precinctSelectionList, productSelection }) => (
  <React.Fragment>

    <div className='box' style={{ display: 'inline-block', marginBottom: 20 }}>
      <div className='box-head' >
        <div>产品</div>
        <div>渠道</div>
        <div>区域</div>
        <div>操作</div>
      </div>
      {fields.map((item, index) => (
        <React.Fragment key={index}>
          <div className='box-body' key={index}>
            <div style={{ overflow: 'visible' }}>
              <FormGroupField
                name={`${item}.productId`}
                label=""
                placeholder="选择销售产品"
                list={productSelection}
                validate={validate3}
                component={NewSelect}
                needText
                onChange={e => { }}
                style={{ width: "200px" }}
              />
            </div>

            <div style={{ overflow: 'visible' }}>
              <FormGroupField
                name={`${item}.channelId`}
                // label="销售渠道&emsp;&emsp;"
                placeholder="选择销售渠道"
                // validate={[selectRequire]}
                list={channelSelection}
                component={NewSelect}
                needText
                validate={validate1}
                onChange={e => { }}
                style={{ width: "200px" }}
              />
            </div>
            <div style={{ overflow: 'visible' }}>
              <FormGroupField
                name={`${item}.regionId`}
                // label="城市经理岗位"
                label=""
                placeholder="选择销售地区"
                validate={validate2}
                list={precinctSelectionList}
                component={NewSelect}
                needText
                onChange={e => { }}
                style={{ width: "200px" }}
              />
            </div>
            <div style={{ overflow: 'visible' }}>
              {fields.length > 1 && <div className="blue" style={{ lineHeight: '50px' }} onClick={(e) => { fields.remove(index) }}>移除</div>}
            </div>
          </div>



        </React.Fragment>
      ))}
      {/* {fields.length < 20 &&  */}
      <div className='box-body'>
        <div style={{ width: 763 }}><AddIcon style={{ cursor: 'pointer' }} onClick={(e) => { fields.push({ regionId: '', productId: '', channelId: '' }) }}></AddIcon></div>
      </div>
      {/* } */}
    </div>

  </React.Fragment>

)


@connect(
  state => {
    return {
      form: state.form.DatadealerDetailForm,
      channelSelection: state.channel.channelSelection,
      productSelection: state.products.selector,
      zoneList: state.zone.zoneList,
      zoneListThreeTree: state.zone.zoneListThreeTree,
      departmentSelectionsList: state.departments.departmentSelectionsList,
      departmentsTreeList: state.departments.departmentsTreeList,
      precinctSelectionList: state.precinct.precinctSelection,
      datadealerFormdata: state.datadealers.datadealerFormdata,
      dealerSelectionList: state.datadealers.dealerSelection,
      serviceStaffIdsList: state.datadealers.serviceStaffIdsList,
      brandSelecList: state.brand.brandSelecList
    };
  },
  {
    postDealer: postDealer.REQUEST,
    putDealer: putDealer.REQUEST,
    loadProductSelect: loadProductSelect.REQUEST,
    loadChannelSelection: loadChannelSelection.REQUEST,
    getZone: getZone.REQUEST,
    departmentSelections: departmentSelections.REQUEST,
    departmentsTree: departmentsTree.REQUEST,
    precinctSelection: precinctSelection.REQUEST,
    loadDealerSelection: loadDealerSelection.REQUEST,
    LoadServiceStaffIds: LoadServiceStaffIds.REQUEST,
    fieldAssignSelection: fieldAssignSelection.REQUEST,
    dealerById: dealerById.REQUEST,
    loadBrandSelections: loadBrandSelections.REQUEST,
    setNavBar
  }
)



class DatadealerDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cityData: [],
      currentTab: 0,
      isEdit: false,
      isEditMore: false,
      initialFields: [],
      parsed: {}
    };
  }
  componentDidMount() {
    this.props.loadProductSelect({ status: 'ENABLE' });
    this.props.loadChannelSelection({ status: 'ENABLE' });
    this.props.loadBrandSelections({ status: 'ENABLE' })
    this.props.getZone();
    this.props.departmentSelections({ status: 'ENABLE' });
    this.props.precinctSelection({ status: 'ENABLE' });

    // 部门树
    this.props.departmentsTree({});
    // this.initForm();
    const query = queryString.parse(this.props.location.search);
    const { id, action, parentId } = query;

    if (id) {
      this.props.setNavBar('编辑经销商')
    } else {
      this.setState({ isEdit: true })
    }

    if (action) this.props.change('action', action);
    console.log(parentId)
    if (parentId) this.props.change('parentId', parentId);
    if (id) {
      this.setState({ loading: true });
      this.props.dealerById({
        id, callback: (res) => {
          setTimeout(() => {
            this.initForm(res);
          }, 1000)
        }
      });
    }

    this.salesRelationsRef = React.createRef()
  }
  componentDidUpdate(prevProps, prevState) {
  }
  componentWillUnmount() {
    this.props.initialize({});
  }

  getAndSetData() {
    const query = queryString.parse(this.props.location.search)
    const { id } = query
    this.props.dealerById({
      id, callback: (res) => {
        this.initForm(res)
      }
    })
  }

  initForm = (res) => {
    this.setState({ loading: false });
    const { datadealerFormdata } = this.props;
    let formdata = JSON.parse(JSON.stringify(res || datadealerFormdata || {}));

    this.setState({ initialFields: res?.dealerRelations || [] })

    // code-> id
    const codeToId = (list, codes, keys) => {
      let ids = [];
      list.map(lv => {
        codes.split(',').map(codesV => {
          if (lv.code == codesV) {
            ids.push(lv.id);
          }
        })
      });
      return ids;
    }


    const { departmentsTreeList = [], departmentSelectionsList = [], precinctSelectionList = [], productSelection = [], zoneListThreeTree = [], channelSelection = [] } = this.props;
    // 部门  departmentSelectionsList, deptCode: string
    if (formdata.deptCode && departmentsTreeList.length) {
      // const ids = codeToId(departmentSelectionsList, formdata.deptCode, 'deptCode');
      // formdata.deptCode = ids.length ? ids[0]: '';
      // formdata.deptCode = formdata.deptCode.split(',');
      // 递归组装deptIds
      let deptIds = [Number(formdata.deptCode)];
      const recursionfun = (code) => {
        let lastDept = departmentSelectionsList.filter(v => v.id == code);
        if (lastDept.length > 0) {
          if (lastDept[0].parentId != 0) {
            deptIds.unshift(lastDept[0].parentId);
            recursionfun(lastDept[0].parentId);
          }
        }
        else {
          return;
        }

      }
      recursionfun(formdata.deptCode);
      this.setState({ deptIds });
    }

    // 销售地区
    // precinctSelectionList, salesPrecinctCod : []
    // if (formdata.salesPrecinctCode && precinctSelectionList.length) {
    //   // formdata.salesPrecinctCode = codeToId(precinctSelectionList, formdata.salesPrecinctCode, 'salesPrecinctCode');
    //   formdata.salesPrecinctCode = formdata.salesPrecinctCode.split(',');
    // }

    // 销售区域
    // zoneListThreeTree, salesAreaCode: []
    if (formdata.salesAreaCode) {
      // formdata.salesAreaCode = codeToId(zoneListThreeTree, formdata.salesAreaCode);
      let arr = formdata.salesAreaCode.split(',');
      let arr2 = [];
      arr.map(v => {
        arr2.push(Number(v));
      })
      formdata.salesAreaCode = arr2;
      this.setState({ cityData: arr2 });
    }

    // 销售渠道
    // channelSelection , distributionChannelCode:[]
    // if (formdata.distributionChannelCode && channelSelection.length) {
    //   // formdata.distributionChannelCode = codeToId(channelSelection, formdata.distributionChannelCode, 'distributionChannelCode');
    //   formdata.distributionChannelCode = formdata.distributionChannelCode.split(',');
    // }



    // if (!formdata.dealerRelations) {
    //   formdata.dealerRelations = [{ regionId: '', productId: '', channelId: '' }]
    //   // formdata.dealerRelations = [{ "regionId": "", "productId": 3, "channelId": "" }, { "regionId": "", "productId": 4, "channelId": "" }]
    // }


    // for (let i = 0; i < formdata.dealerRelations.length; i++) {
    //   let arr = (this.selectorDeal(productSelection, 'id') || []).filter(key => key.id == formdata.dealerRelations[i].productId)[0]
    //   formdata.dealerRelations[i].productId = arr;
    //   let arr2 = (this.selectorDeal(channelSelection, 'id', false) || []).filter(key => key.id == formdata.dealerRelations[i].channelId)[0]
    //   formdata.dealerRelations[i].channelId = arr2;
    //   let arr3 = (this.selectorDeal(precinctSelectionList, 'id', false) || []).filter(key => key.id == formdata.dealerRelations[i].regionId)[0]
    //   formdata.dealerRelations[i].regionId = arr3;
    // }


    // if (formdata.salesProduct && productSelection.length) {
    //   let arr = formdata.salesProduct.split(',')
    //   arr.map(v => {
    //     v = Number(v);
    //   })
    //   formdata.salesProduct = arr;
    // }




    let data = formdata;

    if (data && data.customFields) {
      data.customFields = JSON.parse(data.customFields)
    } else {
      data.customFields = []
    }
    // let salesPrecinctCode = data.salesPrecinctCode && typeof data.salesPrecinctCode == 'string' ? [] : data.salesPrecinctCode;
    // let channelSelections = data.channelSelection && typeof data.channelSelection == 'string' ? [] : data.channelSelection;

    // salesPrecinctCode   
    setTimeout(() => {
      this.props.initialize(data);
      this.props.fieldAssignSelection({
        // data: { dictDataType: 4 },
        data: { dictDataType: 'FRANCHISER' },
        callback: (res) => {
          if (data.customFields && data.customFields.length) {
            let b = res.map((item) => { return { ...item, rate: item.dictType == 'TIME' ? new Date() : '', ...(data.customFields.filter(key => key.code == item.code)[0] || {}) } })
            this.props.change('customFields', b)
          }
          else {
            let b = res.map((item) => { return { ...item, rate: item.dictType == 'TIME' ? new Date() : '' } })
            this.props.change('customFields', b)
          }

        }
      })
    }, 1);
  }

  submit = values => {
    let formdata = {};
    Object.keys(values).map(v => {
      if (values[v]) {
        formdata[v] = JSON.parse(JSON.stringify(values))[v];
      }
    })

    // id-> code
    const idToCode = (list, ids, key) => {
      let codes = [];
      list.map(lv => {
        ids.map(idsV => {
          if (lv.id == idsV && lv.code) {
            codes.push(lv.code);
          }
        })
      });
      return codes.join(',');
    }


    const { departmentSelectionsList = [], precinctSelectionList = [], zoneListThreeTree = [], channelSelection = [] } = this.props;
    // 部门  departmentSelectionsList, deptCode: string
    // if(formdata.deptCode){
    //   formdata.deptCode = idToCode(departmentSelectionsList, [formdata.deptCode], 'deptCode');
    // }
    const { deptIds = [] } = this.state;
    if (deptIds.length > 0) {
      formdata.deptCode = deptIds.splice(deptIds.length - 1, 1)[0];
    }

    // 销售地区
    // precinctSelectionList, salesPrecinctCod : []
    // if (formdata.salesPrecinctCode) {
    //   // formdata.salesPrecinctCode = idToCode(precinctSelectionList, formdata.salesPrecinctCode, 'salesPrecinctCode');
    //   formdata.salesPrecinctCode = formdata.salesPrecinctCode.join(',');
    // }

    // 销售区域
    // zoneListThreeTree, salesAreaCode: []
    if (formdata.salesAreaCode) {
      // formdata.salesAreaCode = idToCode(zoneListThreeTree, formdata.salesAreaCode);
      formdata.salesAreaCode = formdata.salesAreaCode.join(',');

    }

    // 销售渠道
    // channelSelection , distributionChannelCode:[]
    // if (formdata.distributionChannelCode) {
    //   // formdata.distributionChannelCode = idToCode(channelSelection, formdata.distributionChannelCode, 'distributionChannelCode');
    //   formdata.distributionChannelCode = formdata.distributionChannelCode.join(',');
    // }

    // if (formdata.salesProduct) {
    //   formdata.salesProduct = formdata.salesProduct.join(',');
    // }
    // if (formdata.dealerRelations) {
    //   for (let i = 0; i < formdata.dealerRelations.length; i++) {
    //     formdata.dealerRelations[i].regionId = formdata.dealerRelations[i].regionId ? formdata.dealerRelations[i].regionId.value || '' : '';
    //     formdata.dealerRelations[i].channelId = formdata.dealerRelations[i].channelId ? formdata.dealerRelations[i].channelId.value || '' : '';
    //     formdata.dealerRelations[i].productId = formdata.dealerRelations[i].productId ? formdata.dealerRelations[i].productId.value || '' : '';

    //   }
    // }


    // formdata.customFields = values.customFields ? JSON.stringify(values.customFields): '[]';
    formdata.customFields = formdata.customFields ? formdata.customFields.map(item => { return { code: item.code, id: item.id, value: item.value, value1: item.value1, value2: item.value2, value3: item.value3 } }) : []
    formdata.customFields = JSON.stringify(formdata.customFields) || '[]'

    const dealerRelations = this.salesRelationsRef.current?.onSave()
    if (dealerRelations) {
      formdata.dealerRelations = dealerRelations.map(item => ({
        ...item,
        status: formdata.status
      }))
    } else if (formdata.dealerRelations?.length) {
      formdata.dealerRelations = formdata.dealerRelations.map(item => ({
        brandId: item.brandId,
        regionId: item.regionId,
        status: formdata.status
      }))
    }

    formdata.dealerType = 'RETAIL'
    if (values.id) {
      this.props.putDealer({
        data: formdata, callback: () => {
          this.getAndSetData()
          this.setState({ isEdit: false, isEditMore: false })
        }
      });
    } else {
      this.props.postDealer({
        data: formdata, callback: (res) => {
          this.setState({ isEdit: false, isEditMore: false })
          this.props.history.replace(`/databasicData/datadealer/detail?id=${res.id}`)
          this.props.setNavBar('编辑经销商')
          this.getAndSetData()
        }
      });
    }
  };
  // 下拉框列表设置text和value
  selectorDeal = (list, key = 'id', checkcode) => {
    let select = [];
    list.map(v => {
      if (v[key]) {
        v.text = v.name;
        v.value = v[key];
        if (checkcode) {
          if (v.code) {
            select.push(v);
          }
        } else {
          select.push(v);
        }
      }
    })

    return select;
  }

  DummyTransferChange = (a) => {
    this.setState({ cityData: a })
  }
  modalOk = (values) => {
    const { cityData } = this.state;
    // salesAreaCode
    if (cityData.length) {

      this.props.change('salesAreaCode', cityData);
      this.setState({ showSaleArea: false });
    } else {
      notification.error('选择销售区域');
    }
  }

  // 销售区域弹窗
  saleArea = () => {
    return <Dialog
      className={'commonModal saleArea'}
      title="添加销售区域"
      visible={this.state.showSaleArea ? true : false}
      okText="确认"
      cancelText="取消"
      onOk={this.modalOk}
      onCancel={() => { this.setState({ showSaleArea: false }) }}
      shouldCloseOnOverlayClick={false}
      hasFooter={this.state.isEdit}>
      <ListSelector
        value={this.state.cityData}
        list={this.state.isEdit ? this.props.zoneListThreeTree : disabledAreas(this.props.zoneListThreeTree)}
        prepareItemTextFn={(item) => {
          return <span>{item.text}</span>
        }}
        selectedItemTextFn={(item) => {
          return <span>{item.parent.parent.text}-{item.parent.text}-{item.text}</span>
        }}
        onChange={(value) => {
          this.DummyTransferChange(value)
        }}
      ></ListSelector>
    </Dialog>
  }

  render() {
    const { handleSubmit, form = { values: {} }, channelSelection = [], productSelection = [], zoneList = [], departmentSelectionsList = [], precinctSelectionList = [], dealerSelectionList = [] } = this.props;
    const { departmentsTreeList = [] } = this.props;
    const { showSaleArea, deptIdsError, deptIds, loading, isEdit, isEditMore, currentTab } = this.state;
    const { values = {} } = form;
    if (this.state.loading) {
      return <PageLoading />
    } else {

      return (
        <>
        <div className="datadealer-form">

          <FormGroupList>
            <FormGroupField
              name="parentId"
              label="上级经销商"
              disabled={!isEdit || values.id || values.action == 'next' ? true : false}
              placeholder="选择上级经销商"
              component={DynamicDataSelect}
              type="dealer"
              valueName="id"
              style={{ width: "300px" }}
            />
            <FormGroupField
              name="name"
              label="经销商名称"
              required
              placeholder="输入经销商名称"
              validate={[nameRequire, len20]}
              component={Input}
              onChange={e => { }}
              style={{ width: "300px" }}
              disabled={!isEdit}
            />
            <FormGroupField
              name="code"
              label="经销商编码"
              //required
              placeholder="输入经销商编码"
              validate={[codeRequire, len20]}
              component={Input}
              onChange={e => { }}
              style={{ width: "300px" }}
              disabled={!isEdit}
            />
          </FormGroupList>
          {/* 省市区 */}
          <div className="areare">
            <FormGroupList>
              <FormGroupField
                name="provinceCode"
                label="经销商区域"
                placeholder="选择省"
                component={NewSelect}
                style={{ width: 93 }}
                list={this.selectorDeal(zoneList.filter(v => v.level == 1))}
                onChange={e => {
                  this.props.change('cityCode', '')
                  this.props.change('countyCode', '')
                }}
                disabled={!isEdit}
              />
              <FormGroupField
                name="cityCode"
                placeholder="选择市"
                component={NewSelect}
                style={{ width: 93 }}
                list={this.selectorDeal(zoneList.filter(v => values.provinceCode == v.parentCode && v.level == 2))}
                onChange={e => {
                  this.props.change('countyCode', '')
                }}
                disabled={!isEdit}
              />
              <FormGroupField
                name="countyCode"
                placeholder="选择区"
                component={NewSelect}
                style={{ width: 93 }}
                list={this.selectorDeal(zoneList.filter(v => values.cityCode == v.parentCode && v.level == 3))}
                disabled={!isEdit}
              />
            </FormGroupList>
          </div>

          {/* 省市区结束 */}
          <FormGroupList>
            <FormGroupField
              name="address"
              label="经销商地址&emsp;"
              placeholder="输入经销商地址"
              validate={[len100]}
              component={Textarea}
              onChange={e => { }}
              style={{ width: "300px" }}
              disabled={!isEdit}
            />
            <FormGroupField
              name="contact"
              label="联系人"
              placeholder="输入联系人"
              validate={[len50]}
              component={Input}
              onChange={e => { }}
              style={{ width: "300px" }}
              disabled={!isEdit}
            />
            <FormGroupField
              name="mobile"
              label="联系电话"
              placeholder="输入联系电话"
              validate={[validateMobile, len20]}
              component={Input}
              onChange={e => { }}
              style={{ width: "300px" }}
              disabled={!isEdit}
            />
            <FormGroupField
              name="email"
              label="联系邮箱"
              placeholder="输入联系邮箱"
              validate={[validateEmail, len50]}
              component={Input}
              onChange={e => { }}
              style={{ width: "300px" }}
              disabled={!isEdit}
            />
            {/* <FormGroupField
            name="contact"
            label="经销商负责人"
            placeholder="输入经销商负责人"
            validate={[len10]}
            component={Input}
            onChange={e => { }}
            style={{ width: "300px" }}
          />
          <FormGroupField
            name="mobile"
            label="联系电话"
            placeholder="输入联系电话"
            validate={[len20]}
            component={Input}
            onChange={e => { }}
            style={{ width: "300px" }}
          />
          <FormGroupField
            name="email"
            label="联系邮箱"
            placeholder="输入联系邮箱"
            validate={[len50]}
            component={Input}
            onChange={e => { }}
            style={{ width: "300px" }}
          /> */}
            {/* <FormGroupField
              name="deptCode"
              label="管理部门"
              placeholder="选择管理部门"
              // validate={[selectRequire]}
              component={NewSelect}
              list={this.selectorDeal(departmentSelectionsList, 'id', false)}
              onChange={e => {}}
              style={{ width: "300px" }}
            /> */}
          </FormGroupList>
          {/* <div className="form-list deptId-box">
          <div style={{ display: "table-row" }}>
            <div className="form-label deptId-label">
              <label>管理部门</label>
            </div>
            <div className="form-input deptId-input">
              <div className={deptIdsError ? "form-unit is-error" : "form-unit"}>
                <Cascader
                  value={deptIds}
                  className="antdCascader"
                  options={departmentsTreeList}
                  fieldNames={{ label: 'name', value: 'id', children: 'children' }}
                  placeholder="选择管理部门"
                  notFoundContent="暂无数据"
                  expandTrigger="hover"
                  changeOnSelect
                  allowClear={false}
                  onChange={(value) => {
                    this.setState({ deptIds: value, deptIdsError: '' });
                  }}
                />
                <i className="tc-15-select deptId-icon"></i>
                <b className="icon-valid-flag" title={deptIdsError}></b>
              </div>
              <span className="deptIdsError-red">{deptIdsError}</span>
            </div>
          </div>
        </div> */}
          <FormGroupList>
            {/* TODO: 添加 */}
            <StaticFormGroup
              name="salesAreaCode"
              label="销售区域&emsp;&emsp;"
              component={Input}
            >
              <span className="blue" onClick={() => { this.setState({ showSaleArea: true }) }}>添加</span>
            </StaticFormGroup>
            {/* <FormGroupField
            name="salesAreaCode"
            label="销售区域"
            placeholder="添加"
            // validate={[selectRequire]}
            component={Input}
            onChange={e => {}}
            style={{ width: "300px" }}
          /> */}
            {this.saleArea()}

          </FormGroupList>
          {/* <h4 style={{ fontSize: '14px', fontWeight: 'bold' }}>区域信息</h4>
        <FieldArray className='dealerRelations' name="dealerRelations" component={table}
          channelSelection={this.selectorDeal(channelSelection, 'id', false) || []}
          precinctSelectionList={this.selectorDeal(precinctSelectionList, 'id', false) || []}
          productSelection={this.selectorDeal(productSelection, 'id') || []}
        /> */}

          {/* <FormGroupField
            name="distributionChannelCode"
            label="销售渠道"
            placeholder="选择销售渠道"
            // validate={[selectRequire]}
            list={this.selectorDeal(channelSelection, 'id', false) || []}
            component={NewSelect}
            component={MultipleSelect}
            onChange={e => {}}
            style={{ width: "300px" }}
          /> */}
          {/* <FormGroupField
            name="salesPrecinctCode"
            label="城市经理岗位"
            // label="销售地区"
            placeholder="选择销售地区"
            // validate={[selectRequire]}
            list = {this.selectorDeal(precinctSelectionList, 'id', false) || []}
            component={MultipleSelect}
            // component={NewSelect}
            onChange={e => {}}
            style={{ width: "300px" }}
          /> */}
          {/* <FormGroupField
            name="salesProduct"
            label="销售产品"
            placeholder="选择销售产品"
            list={this.selectorDeal(productSelection, 'id') || []}
            // validate={[selectRequire]}
            component={MultipleSelect}
            // component={NewSelect}
            onChange={e => { }}
            style={{ width: "300px" }}
          /> */}
          {/* customFields */}

          <div className="btn-box">
            <Button
              className="weak mr-10"
              onClick={() => {
                this.props.history.push("/datadealer");
              }}>
              取消
            </Button>
            {isEdit ? <Button onClick={handleSubmit(this.submit)}>保存</Button> : <Button className="weak-button" onClick={() => {
              if (isEditMore && this.state.currentTab === 0) {
                notification.error('请先保存自定义字段')
              } else if (isEditMore && this.state.currentTab === 1) {
                notification.error('请先保存销售关系')
              } else {
                this.setState({ isEdit: true })
              }
            }}>编辑</Button>}
          </div>
        </div>

        <div className="datadealer-form">
          <TabList
            list={[
              { text: '自定义字段', value: 0 },
              { text: '销售关系', value: 1 },
              ...(values.code
                 ? [
                      { text: '经销商积分', value: 2 },
                      { text: '人员信息', value: 3 },
                  ]
                  : [])
            ]}
            value={this.state.currentTab}
            onChange={e => {
              this.setState({ currentTab: e, isEditMore: false })
            }}
          />

          {this.state.currentTab === 0 && <CustomFields disabled={!isEditMore} margin='no' name='customFields' handleChange={e => {
            const { index, url } = e
            let arr = values?.customFields || []
            arr = arr.map((it, i) => {
              if (i === +index) {
                return {
                    ...it,
                    value: url || ''
                }
              } else {
                return it
              }
            })

            this.props.change('customFields', arr)
          }}></CustomFields>}

          {this.state.currentTab === 1 && <SalesRelations visible={true} disabled={!isEditMore} ref={this.salesRelationsRef} initialFields={this.state.initialFields} />}

          <div>
            {this.state.currentTab === 2 && <ShopCredit appId={process.env.env === 'prod' ? 'wxe624a1cddc0ce950' : 'wx5beb96ee823dd86a'} openId={`${values.code}`} title="经销商积分记录" authType="FRANCHISER" />}
            {this.state.currentTab === 3 && <DealersInfo code={values.code} />}
          </div>

          {
            (this.state.currentTab === 0 || this.state.currentTab === 1) &&
            <div style={{ marginTop: 20 }}>
              {isEditMore ? <Button onClick={handleSubmit(this.submit)}>保存</Button> : <Button className="weak-button" onClick={() => {
                if (isEdit) {
                  notification.error('请先保存经销商基本信息')
                } else {
                  this.setState({ isEditMore: true })
                }
              }}>编辑</Button>}
            </div>
          }
        </div>
        </>
      );
    }
  }
}
const DatadealerDetailForm = reduxForm({
  form: "DatadealerDetailForm", initialValues: {
    // distributionChannelCode: [], salesPrecinctCode: [], salesProduct: []
  }
})(
  DatadealerDetail
);
export default DatadealerDetailForm;
