import React, { useState, useMemo, useCallback, forwardRef, useImperativeHandle, useEffect } from 'react'
import { NewSelect } from 'ucode-components'
import AddIcon from '@/icons/AddIcon'
import '../index.scss'
import { notification } from 'tyb'
import { MultipleSelect } from 'ucode-components'
import DynamicDataSelect from '@/components/DynamicDataSelect'
import { useSelector } from '@/components/hooks'

/**
 * @typedef {object} Field
 * @prop {string} id
 * @prop {string[]} DEALER
 * @prop {string[]} SALESMAN
 * @prop {string[]} brandId
 * @prop {string[]} PRODUCT
 * @prop {string[]} SALES_CHANNEL
 * @prop {string[]} regionId
 */

function SalesRelations(props, ref) {
  const { visible = false, disabled = false, initialFields } = props

  const brandList = useSelector(state => state.brand.brandSelecList || [])
  const precinctList = useSelector(state => state.precinct.precinctSelection || [])

  /** @type {[Field[]], any} */
  const [fields, setFields] = useState([])

  const onSave = useCallback(() => {
    const list = fields.map(item => ({
      brandId: item.brandId,
      regionId: item.regionId
    }))

    return list
  }, [fields])

  const setInitialFields = useCallback(function(list) {
    setFields(
      list?.map(item => ({
        id: item.id || `${Date.now()}${Math.random()}`,
        brandId: item.brandId,
        regionId: item.regionId
      })) || []
    )
  }, [])

  useEffect(() => {
    if (initialFields.length) {
      setInitialFields(initialFields)
    }
  }, [initialFields, setInitialFields])

  useImperativeHandle(
    ref,
    () => ({
      onSave,
      setInitialFields
    }),
    [onSave, setInitialFields]
  )

  const onFieldChange = useCallback(({ key, value, label, index }) => {
    setFields(list =>
      list.map((target, targetIndex) => {
        if (targetIndex === index) {
          return {
            ...target,
            [key]: value
          }
        } else {
          return target
        }
      })
    )
  }, [])

  const columns = useMemo(
    () => [
      // {
      //   key: 'DEALER',
      //   dataIndex: 'DEALER',
      //   title: '经销商',
      //   width: '15%',
      //   render(value, record, index) {
      //     return (
      //       <div>
      //         <DynamicDataSelect
      //           type="dealer"
      //           style={{ width: '100%' }}
      //           value={value}
      //           multiple
      //           list={selects.dealers.map(item => ({
      //             text: item.name,
      //             value: item.id
      //           }))}
      //           onChange={e => {
      //             onFieldChange({
      //               key: 'DEALER',
      //               value: e,
      //               index
      //             })
      //           }}
      //         />
      //       </div>
      //     )
      //   }
      // },
      // {
      //   key: 'SALESMAN',
      //   dataIndex: 'SALESMAN',
      //   title: '服务人员',
      //   width: '15%',
      //   render(value, record, index) {
      //     return (
      //       <div>
      //         <MultipleSelect
      //           style={{ width: '100%' }}
      //           list={selects.salesman}
      //           value={value}
      //           onChange={e => {
      //             onFieldChange({ key: 'SALESMAN', value: e, index })
      //           }}
      //         />
      //       </div>
      //     )
      //   }
      // },
      {
        key: 'brandId',
        dataIndex: 'brandId',
        title: '品牌',
        width: '20%',
        render(value, record, index) {
          return (
            <div>
              <NewSelect
                style={{ width: '100%' }}
                list={brandList.map(item => ({
                  text: item.name,
                  value: item.id
                }))}
                value={value}
                onChange={e => {
                  onFieldChange({ key: 'brandId', value: e, index })
                }}
                disabled={disabled}
              />
            </div>
          )
        }
      },
      // {
      //   key: 'PRODUCT',
      //   dataIndex: 'PRODUCT',
      //   title: '产品',
      //   width: '15%',
      //   render(value, record, index) {
      //     return (
      //       <div>
      //         <MultipleSelect
      //           style={{ width: '100%' }}
      //           list={selects.products}
      //           value={value}
      //           onChange={e => {
      //             onFieldChange({ key: 'PRODUCT', value: e, index })
      //           }}
      //         />
      //       </div>
      //     )
      //   }
      // },
      // {
      //   key: 'SALES_CHANNEL',
      //   dataIndex: 'SALES_CHANNEL',
      //   title: '渠道',
      //   width: '15%',
      //   render(value, record, index) {
      //     return (
      //       <div>
      //         <MultipleSelect
      //           style={{ width: '100%' }}
      //           list={selects.sales_channels.map(item => ({
      //             text: item.name,
      //             value: item.id
      //           }))}
      //           value={value}
      //           onChange={e => {
      //             onFieldChange({ key: 'SALES_CHANNEL', value: e, index })
      //           }}
      //         />
      //       </div>
      //     )
      //   }
      // },
      {
        key: 'regionId',
        dataIndex: 'regionId',
        title: '区域',
        width: '20%',
        render(value, record, index) {
          return (
            <div>
              <NewSelect
                style={{ width: '100%' }}
                list={precinctList.map(item => ({
                  text: item.name,
                  value: item.id
                }))}
                value={value}
                onChange={e => {
                  onFieldChange({ key: 'regionId', value: e, index })
                }}
                disabled={disabled}
              />
            </div>
          )
        }
      },
      {
        key: 'option',
        title: '操作',
        render: (_, record, index) => (
          <div>
            <button
              style={{ backgroundColor: 'transparent', color: '#1469FB' }}
              onClick={() => {
                setFields(list => list.filter((_, i) => i !== index))
              }}
              disabled={disabled}>
              移除
            </button>
          </div>
        )
      }
    ],
    [onFieldChange, brandList, precinctList, disabled]
  )

  if (!visible) {
    return <></>
  }

  return (
    <div>
      <div className="SalesRelations-table-header">
        {columns.map(column => (
          <div key={column.key} style={{ width: column.width }}>
            {column.title}
          </div>
        ))}
      </div>

      <div className="SalesRelations-table-body">
        {fields.map((row, rowIndex) => (
          <div key={row.id} className="SalesRelations-table-row">
            {columns.map(column => (
              <div key={column.key} style={{ width: column.width }}>
                {column.render
                  ? column.render(row[column.dataIndex], row, rowIndex)
                  : row[column.dataIndex] || ''}
              </div>
            ))}
          </div>
        ))}
      </div>

      <div className="SalesRelations-addcolumn">
        <AddIcon
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if (disabled) { return }
            setFields(list => [
              ...list,
              {
                id: `${Date.now()}${Math.random()}`,
                DEALER: [],
                SALESMAN: [],
                brandId: [],
                PRODUCT: [],
                SALES_CHANNEL: [],
                regionId: []
              }
            ])
          }}
        />
      </div>
    </div>
  )
}

export default forwardRef(SalesRelations)
